import React, { useContext, useEffect, useState } from "react";
import { selectPeers, useHMSStore } from "@100mslive/react-sdk";
import { useHMSActions } from "@100mslive/react-sdk";
import Peers from "./components/Peers";
import Controls from "./components/PeersControl";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Chat from "./components/Chat";
import KineContext from "../../context/KineContext";
import { useNavigate } from "react-router-dom";

function Meet() {
  const { loggeduser, setLoggedUser } = useContext(KineContext);
  const [showModal, setShowModal] = useState(true);
  const [guestName, setGuestName] = useState(""); // Para armazenar o nome do convidado
  const peers = useHMSStore(selectPeers);
  const hmsActions = useHMSActions();
  const navigate = useNavigate();

  const joinConference = async () => {
    try {
      const param = window.location.pathname.split("/")[2];
      const authToken = await hmsActions.getAuthTokenByRoomCode({
        roomCode: param,
      });

      try {
        await hmsActions.join({
          userName: loggeduser?.name || "Guest",
          authToken,
        });
      } catch (e) {
        console.error(e);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onClose = () => {
    setShowModal(false);
  };

  const handleGuestLogin = () => {
    if (guestName.trim()) {
      setLoggedUser({ name: guestName }); // Atualiza o contexto com o nome do convidado
      setShowModal(false); // Fecha o modal
    }
  };

  const copy = () => {
    const tempInput = document.createElement("input");
    tempInput.value = window.location.href;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  };

  const handleNameChange = (param) => {
    setGuestName(param)
  }

  useEffect(() => {
    if (!loggeduser || !loggeduser.name) {
      setShowModal(true); // Mostra o modal para convidado
    } else {
      joinConference();
    }
  }, [loggeduser]);

  return (
    <div className="flex flex-col item-start gap-4 meet-container">
      <div>
        <div className="flex justify-center gap-4 items-center flex-wrap">
          {peers.map((peer, key) => (
            <Peers key={peer.id} peer={peer} />
          ))}
        </div>
        <Controls />
      </div>

      <div className="container">
        <Chat />
      </div>
      <div className="absolute top-20 right-4 z-[10]">
        {showModal && loggeduser &&  !loggeduser.name && (
          <Modal
            title="Join as Guest"
            onClose={onClose}
            onCancel={onClose}
            size="small"
          >
            <div className="flex flex-col gap-4">
              <p className="mono w-[90%]">
                Enter your name to join the meeting as a guest.
              </p>
              <Input
              type='text'
                value={guestName}
                onChange={handleNameChange}
                placeholder="Your name"
              />
              <button
                onClick={handleGuestLogin}
                className="btn-primary w-full"
              >
                Join as Guest
              </button>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default Meet;
